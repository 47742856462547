<div class="faqs__content" *ngIf="__step === 2">

    <p>The Spin the Wheel campaign is a Tiger Brands competition that will run from the 27 August - 7 October 2020. The competition encourages consumers to opt-in to receiving marketing and promotional communication from Tiger Brands. Customer who choose to play Spin The Wheel stand a chance to win R10 &R20 airtime vouchers as well as product vouchers. These prizes can be redeemed at Shoprite/Checkers retail stores nationwide.</p>
    
    <p>When a consumer receives communication from Tiger Brands, they will be requested to firstly opt-in to receiving future messaging from us. Once opted in, they will receive a link to a platform where they can spin-to-win airtime or product vouchers to redeem on selected Tiger Brands products.</p>

    <a href="javascript:void(0)" (click)="faqs()" class="button">Click here for FAQs</a>

    <div class="wrapper__slider">
    
        <div class="wrapper__slider--item" style="background-image: url('assets/slider/slider-blackcat.jpg'); background-color: #E42629;"></div>
    
        <div class="wrapper__slider--item" style="background-image: url('assets/slider/slider-fattis.jpg'); background: linear-gradient(0deg, rgba(227,37,38,1) 0%, rgba(99,4,0,1) 100%);"></div>
    
        <div class="wrapper__slider--item" style="background-image: url('assets/slider/slider-tastic.jpg');"></div>
    
        <div class="wrapper__slider--item" style="background-image: url('assets/slider/slider-crosse.jpg');"></div>
    
        <div class="wrapper__slider--item mobile" style="background-image: url('assets/slider/slider-beacon.jpg');"></div>
    
        <div class="wrapper__slider--item" style="background-image: url('assets/slider/slider-roses.jpg');"></div>
    
        <div class="wrapper__slider--item" style="background-image: url('assets/slider/slider-ace.jpg');"></div>
    
        <div class="wrapper__slider--item" style="background-image: url('assets/slider/slider-msballs.jpg');"></div>
    
        <div class="wrapper__slider--item mobile" style="background-image: url('assets/slider/slider-oros.jpg');"></div>
    
        <div class="wrapper__slider--item mobile" style="background-image: url('assets/slider/slider-allgold.jpg');"></div>
    
    </div>

</div>

<div class="faqs__content" *ngIf="__step === 3">

    <p class="heading">Here are some FAQs to help answer any questions you may have about the Spin The Wheel competition promotion.</p>

    <p class="heading nomargin">How do I enter the Spin The Wheel competition?</p>
    
    <p>By opting-in to receive communication from Tiger Brands you automatically qualify to enter
        the Spin The Wheel competition.</p>

    <p class="heading nomargin">How long is the duration of the Spin The Wheel competition?</p>
    
    <p>The Spin the Wheel competition promotion is a Tiger Brands competition that will run from
        the 27 August 2020 to 7 October 2020</p>

    <p class="heading nomargin">Who can enter the Spin The Wheel competition?</p>
    
    <p>To be eligible to enter the competition, you must be:<br>
        A resident of South Africa 18 years or older. However, no director, employee, agent or consultant of the Tiger Brands Group of
        Companies, or their spouses, life partners, business partners or immediate family members,
        or the supplier of goods and services in connection with this competition may participate in
        this campaign.</p>

    <p class="heading nomargin">What can I win?</p>
    
    <p>A participant can win airtime & data vouchers valued at R10 and R20.Win R10 airtime &data
        vouchers, R20 airtime & data vouchers, R50 product vouchers, R100 product vouchers, an
        entry into the Grand Draw for R1000 Shoprite/Checkers grocery voucher – one a week for 6
        weeks
        The airtime is for pay as you go/pre-paid and top-up plans only (you can't load airtime to
        contracts).</p>

    <p class="heading nomargin">When will the winners be picked?</p>
    
    <p>R10 and R20 airtime vouchers as well as R50 and R100 product voucher winners will be
        picked every day for the duration of the draw. The R1000 Shoprite/Checkers grocery winner
        is chosen once a week for 5 weeks.</p>

    <p class="heading nomargin">How do I redeem my prize?</p>
    
    <p>After opting-in to receive communication from Tiger Brands and Spinning The Wheel, should
        a participant land on any airtime or a Shoprite/Checkers voucher slice they are directed to a
        screen that will:<br>
        Ask them to fill in their name<br>
        Ask them for their cellphone number<br>
        Inform them the value of their win<br>
        The prize will be sent to the participant as a digital voucher over SMS. Participants should
        show this voucher to the cashier when they make their purchase</p>

    <p class="heading nomargin">Who can I contact regarding my prize win?</p>
    
    <p>Email: <a href="mailto:tigercsd@tigerbrands.com">tigercsd@tigerbrands.com</a><br>
        Call: <a href="tel:0860101107">0860101107</a></p>

    <p class="heading nomargin">How do I opt-out?</p>
    
    <p>When you opt-in to receiving communication from Tiger Brands, you’ll also be given an
        option to opt-out should you not want to receive communication from Tiger Brands.</p>

    <p class="heading nomargin">What are the Terms and Conditions for the Spin The Wheel competition promotion?
        TIGER BRAND SPIN THE WHEEL - T&Cs ("COMPETITION RULES")</p>
    
    <p>To view the Terms and Conditions, <a href="https://tigerbrandssa.co.za/terms_and_conditions.pdf" target="_blank">Click here</a>.</p>

</div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { MainService } from 'src/app/main.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import axios from "axios";

declare var Spin2WinWheel: any;

@Component({
  selector: 'app-wheel',
  templateUrl: './wheel.component.html',
  styleUrls: ['./wheel.component.scss']
})
export class WheelComponent implements OnInit {

  // step
  __step: number = 0;

  // wheel
  wheel: any = undefined;
  // url:any = 'https://tigerbrandssa.co.za';
  url:any = 'https://wheel.imgraham.co.za';
  gameId:any = '';
  public guid: any = '';
  questionData:any = {};
  questionAnswerID: any = '';
  questionAnswers: any = [];
  question:any = '';
  answeredQuestion: any = false;
  showOtherValue: any = false;
  showCellphoneMobile: any = false;

  // form question
  formGroupQuestion: FormGroup;
  fieldSelect: AbstractControl;
  fieldAnswer: AbstractControl;

  // form cellphone number
  formGroupCellphone: FormGroup;
  fieldCellphone: AbstractControl;

  constructor(
    private formBuilder: FormBuilder,
    public service: MainService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) {

    // question
    this.formGroupQuestion = formBuilder.group({
      fieldSelect: ['', Validators.required],
      fieldAnswer: ['']
    });
    this.fieldSelect = this.formGroupQuestion.controls['fieldSelect'];
    this.fieldAnswer = this.formGroupQuestion.controls['fieldAnswer'];

    // cellphone
    this.formGroupCellphone = formBuilder.group({
      fieldCellphone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^0(3|4|5|6|7|8){1}[0-9]{1}[0-9]{7}$")]]
    });
    this.fieldCellphone = this.formGroupCellphone.controls['fieldCellphone'];

  }

  ngOnInit(): void {

    this.service.dataStepsEntry.subscribe( (__step) => {

      this.__step = __step;

      if(__step === 1) {

        this.guid = this.getParamValueQueryString('guid');

        if(this.guid) {

          if(this.guid.match(/^0(3|4|5|6|7|8){1}[0-9]{1}[0-9]{7}$/)) {

            this.vouchers();

          }else{

            this.showCellphoneMobile = true;

          }

        }else{

         this.showCellphoneMobile = true;

        }

      }

    });

  }

  // API CALLS
  spin() {

    this.validateAllFormFields(this.formGroupCellphone);
    if(this.formGroupCellphone.valid) {
      this.guid = this.fieldCellphone.value;
      this.showCellphoneMobile = false;
      this.vouchers()
    }

  }

  vouchers() {
    let postData = {
      guid: this.guid
    };
    this.http.post(this.url + '/api/vouchers', postData).subscribe(
      response => {
        let data: any = response;

        // begin number of spins left
        if(data.numSpins > 0) {

          // begin if you have won a prize
          if(data.win) {

            this.service.setLoading(false);
            this.service.setWinner(data.win);

            // begin if you have have voucher assigned
            if(data.win.voucher) {

              this.service.setStep(5);

            }else{

              this.service.setStep(4);

            }
            // end if you have have voucher assigned

          }else{

            // begin if you have a lastQuestion that needs answering
            if(data.lastQuestion) {

              let selectOptions: any = "";
              let selectAnswer: any = document.querySelector('.js-populate-answers');
              selectOptions += '<option value="">Please select</option>';
              data.lastQuestion.answers.forEach(answer => {
                selectOptions += '<option value="'+answer.answer+'">'+answer.answer+'</option>';
              });
              if(data.lastQuestion.question_id === 4) {
                selectOptions += '<option value="Other">Other</option>';
              }
              if(data.lastQuestion.question_id === 5) {
                selectOptions += '<option value="None of the above">None of the above</option>';
              }
              selectAnswer.innerHTML = selectOptions;

              let questionDiv: any = document.querySelector('.modal__content--question');
              questionDiv.innerHTML = data.lastQuestion.question;
              this.questionAnswerID = data.lastQuestion.id;
              this.showModal();
              this.createWheel(data);

            }else{

              this.createWheel(data);

            }
            // end if you have a lastQuestion that needs answering

          }
          // end if you have won a prize

        }else{

          if(data.win) {

            this.service.setLoading(false);
            this.service.setWinner(data.win);

            // begin if you have have voucher assigned
            if(data.win.voucher) {

              this.service.setStep(5);

            }else{

              this.service.setStep(4);

            }
            // end if you have have voucher assigned

          }else{

            this.service.setLoading(false);
            let modal = document.querySelector('.modal__wrong');
            modal.classList.add('visible');

          }

        }
        // end number of spins left

      },
      error => {
        console.log(error);
      }
    );
  }

  spinAgain() {

    // detect value
    if(this.fieldSelect.value === 'Other'){
      this.fieldAnswer.setValidators([Validators.required]);
    }else{
      this.fieldAnswer.setValidators(null);
    }

    // validate form
    this.validateAllFormFields(this.formGroupQuestion);

    if(this.formGroupQuestion.valid) {

      // post data
      let postData: any = {};
      if(this.fieldSelect.value === 'Other'){
        postData = {
          game_id: this.gameId,
          question_answer_id: this.questionAnswerID,
          answer: this.fieldAnswer.value
        };
      }else{
        postData = {
          game_id: this.gameId,
          question_answer_id: this.questionAnswerID,
          answer: this.fieldSelect.value
        };
      }

      this.answeredQuestion = true;
      this.http.post(this.url + '/api/question/answer', postData).subscribe(
        response => {

          this.showOtherValue = false;
          this.fieldAnswer.setValidators(null);
          let data:any = response;
          if(data.success) {
            this.formGroupQuestion.reset();
            this.answeredQuestion = false;
            this.hideModal();
            this.wheel.trigger();
          }else{
            this.hideModal();
            let modal = document.querySelector('.modal__wrong');
            modal.classList.add('visible');
          }

        },
        error => {
          console.log(error);
        }
      );
    }

  }

  logSpin(result: any = {}, numSpins: any = 0, gameId: any = '', product_id: any = '', brand_name: any = '', sku: any = '', won: any = false) {
    let postData = {
      game_id: gameId,
      product_id: product_id,
      cellphone: this.guid,
      brand_name: brand_name,
      sku: sku,
      won: won
    };
    return this.http.post(this.url + '/api/spin', postData).subscribe(
      response => {

        let triggerLoadingStop: any = document.querySelector('.js-trigger-loading-stop');
        triggerLoadingStop.click();

        let data: any = response;

        if(result.win) {

          let inputData: any = document.querySelector('.js-value-winner');
          inputData.value = JSON.stringify(data);

          let triggerWinner: any = document.querySelector('.js-trigger-winner');
          triggerWinner.click();

        }else{

          let newSpinCount: any = result.spinCount + 1;
          if(newSpinCount === numSpins) {

            this.voucherR1000Winner();

          }else{

            this.getQuestion();

          }

        }
      },
      error => {
        console.log(error);
      }
    );
  }

  voucherR1000Winner() {
    let postData = {
      game_id: this.gameId,
      cellphone: this.guid
    };
    return this.http.post(this.url + '/api/voucher1000', postData).subscribe(
      response => {

        let triggerLoadingStop: any = document.querySelector('.js-trigger-loading-stop');
        triggerLoadingStop.click();

        let data: any = response;

        if(data.success) {

          let inputData: any = document.querySelector('.js-value-winner');
          inputData.value = JSON.stringify(data.product);

          let triggerWinner: any = document.querySelector('.js-trigger-winner');
          triggerWinner.click();

        }else{

          let modal = document.querySelector('.modal__wrong');
          modal.classList.add('visible');

        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getQuestion() {
    this.service.setLoading(true);
    let postData = {
      game_id: this.gameId
    };
    return this.http.post(this.url + '/api/question', postData).subscribe(
      response => {

        let triggerLoadingStop: any = document.querySelector('.js-trigger-loading-stop');
        triggerLoadingStop.click();

        this.service.setLoading(false);
        let data: any = response;

        let selectOptions: any = "";
        let selectAnswer: any = document.querySelector('.js-populate-answers');
        selectAnswer.innerHTML = '';
        selectOptions += '<option value="">Please select</option>';
        data.answers.forEach(answer => {
          selectOptions += '<option value="'+answer.answer+'">'+answer.answer+'</option>';
        });
        if(data.question_id === 4) {
          selectOptions += '<option value="Other">Other</option>';
        }
        if(data.question_id === 5) {
          selectOptions += '<option value="None of the above">None of the above</option>';
        }
        selectAnswer.innerHTML = selectOptions;

        let questionDiv: any = document.querySelector('.modal__content--question');
        questionDiv.innerHTML = data.question;
        this.questionAnswerID = data.id;
        this.showModal();

      },
      error => {
        console.log(error);
      }
    );
  }

  // create wheel
  createWheel(response: any = {}) {
    this.service.setLoading(false);
    this.gameId = response.gameId;
    this.wheel = new Spin2WinWheel();
    this.wheel.init({
      data:response,
      onResult:(result)=>{
        this.onResult(result, response.numSpins);
      },
      onGameEnd:this.onGameEnd,
      onError:this.onError
    });
  }

  // on results
  onResult(result: any = {}, numSpins:any = undefined) {

    let triggerLoading: any = document.querySelector('.js-trigger-loading');
    triggerLoading.click();

    this.logSpin(
      result,
      numSpins,
      this.gameId,
      result.userData.product_id,
      result.userData.brand,
      result.userData.sku,
      result.win
    );

  }

  onGameEnd(result = '') {
    // let modal = document.querySelector('.modal__wrong');
    // modal.classList.add('visible');
  }

  onError(error = '') {
    console.log(error);
  }

  // show & hides
  showModal() {
    let modal = document.querySelector('.modal');
    modal.classList.add('visible');
  }

  showLoading() {
    this.service.setLoading(true);
  }

  showLoadingStop() {
    this.service.setLoading(false);
  }

  hideModal() {
    let modal = document.querySelector('.modal');
    modal.classList.remove('visible');
  }

  // on change
  changeScreen() {

    let inputData: any = document.querySelector('.js-value-winner');
    let data: any = JSON.parse(inputData.value);

    this.service.setStep(4);
    this.service.setWinner(data);

  }

  changedSelect(evt: any) {
    let element: any = undefined;
    if(evt.currentTarget) {
      element = evt.currentTarget;
    }else{
      element = evt.target;
    }
    let value: any = element.value;
    if(value === 'Other') {
      this.fieldAnswer.setValidators([Validators.required]);
      this.showOtherValue = true;
    }else{
      this.fieldAnswer.setValidators(null);
      this.showOtherValue = false;
    }
  }

  // extras
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  getParamValueQueryString( paramName ) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MainService } from 'src/app/main.service';

@Component({
  selector: 'app-winner',
  templateUrl: './winner.component.html',
  styleUrls: ['./winner.component.scss']
})
export class WinnerComponent implements OnInit {

  // step
  url:any = 'https://wheel.imgraham.co.za';
  __step: number = 0;
  __winner: any = {};

  // form
  formRedeem: FormGroup;
  fieldName: AbstractControl;
  __answered: any = false;

  constructor( private formBuilder: FormBuilder,private service: MainService, private http: HttpClient ) {

    this.formRedeem = formBuilder.group({
      fieldName: ['', Validators.required]
    });
    this.fieldName = this.formRedeem.controls['fieldName'];

  }

  ngOnInit(): void {

    this.service.dataStepsEntry.subscribe( (__step) => {

      this.__step = __step;

    });

    this.service.dataWinnerEntry.subscribe( (__winner) => {

      this.__winner = __winner;

    });

  }

  submit() {
    this.validateAllFormFields(this.formRedeem);
    if(this.formRedeem.valid) {
      this.__answered = true;
      let postData = {
        game_id: this.__winner.game_id,
        product_id: this.__winner.id,
        name: this.fieldName.value,
        cellphone: this.__winner.cellphone
      };
      return this.http.post(this.url + '/api/redeem', postData).subscribe(
        response => {
          let data: any = response;
          if(data.success) {
            this.service.setStep(5);
            this.service.setWinner(data.win);
          }
          console.log(response)
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

}

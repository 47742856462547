<div class="winner" *ngIf="__step === 5">

    <div class="winner__heading">

        congratulations YOU’VE WON

    </div>

    <div class="winner__content" *ngIf="__winner?.campaignId === 'Tiger_SpinToWin_PROD_202008_Airtime_Pinless_R10'">

        <div class="winner__content--title">

            <div class="winner__content--title__main--airtime">

                R10
                
            </div>

            <div class="winner__content--title__description--airtime">

                Airtime voucher

            </div>

        </div>

    </div>

    <div class="winner__content" *ngIf="__winner?.campaignId === 'Tiger_SpinToWin_PROD_202008_Airtime_Pinless_R20'">

        <div class="winner__content--title">

            <div class="winner__content--title__main--airtime">

                R20
                
            </div>

            <div class="winner__content--title__description--airtime">

                Airtime voucher

            </div>

        </div>

    </div>

    <div class="winner__content" *ngIf="__winner?.campaignId === '(v3)Spintowin_R50_Shoprite_tiger'">

        <div class="winner__content--logo" [style.backgroundImage]="'url('+__winner?.image+')'"></div>

        <div class="winner__content--title">

            <div class="winner__content--title__main">

                R50
                
            </div>

            <div class="winner__content--title__description">

                Product voucher

            </div>

        </div>

    </div>

    <div class="winner__content" *ngIf="__winner?.campaignId === '(v3)Spintowin_R100_Shoprite_tiger'">

        <div class="winner__content--logo" [style.backgroundImage]="'url('+__winner?.image+')'"></div>

        <div class="winner__content--title">

            <div class="winner__content--title__main">

                R100
                
            </div>

            <div class="winner__content--title__description">

                Product voucher

            </div>

        </div>

    </div>

    <div class="winner__form">

        <div class="winner__form--label">

            Your voucher code

        </div>

        <div class="winner__form--voucher">

            {{ __winner?.voucher }}

        </div>

    </div>

    <div class="winner__grandprize">

        YOU’RE eligible for the weekly grand prize of a R1000 grocery voucher

    </div>

    <div class="winner__valid">

        VALID: 27/08/2020 T0 07/10/2020

    </div>

    <div class="winner__terms">

        TERMS & CONDITIONS: To view the Terms & Conditions, <a href="/terms_and_conditions.pdf" target="_blank">Click Here</a>

    </div>

</div>
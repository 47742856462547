import { Component, OnInit } from '@angular/core';
import { MainService } from '../main.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  // step
  __step: number = 0;

  constructor(private service: MainService) {
    
  }

  ngOnInit(): void {

    this.service.dataStepsEntry.subscribe( (__step) => {
      
      this.__step = __step;
      
    });

  }

  faqs() {

    this.service.setStep(3);

  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MainService } from 'src/app/main.service';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {

  // step
  __step: number = 0;
  __winner: any = {};

  constructor(private service: MainService) { }

  ngOnInit(): void {

    this.service.dataStepsEntry.subscribe( (__step) => {
      
      this.__step = __step;
      
    });

    this.service.dataWinnerEntry.subscribe( (__winner) => {
      
      this.__winner = __winner;
      
    });

  }

}

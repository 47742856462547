import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WheelComponent } from './wheel/wheel.component';
import { FaqsComponent } from './faqs/faqs.component';
import { WinnerComponent } from './winner/winner.component';

const routes: Routes = [

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

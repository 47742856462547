import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs"; 

@Injectable({
  providedIn: 'root'
})
export class MainService {

  public dataStepsSource = new BehaviorSubject<any>(1);
  dataStepsEntry = this.dataStepsSource.asObservable();

  public dataWinnerSource = new BehaviorSubject<any>({});
  dataWinnerEntry = this.dataWinnerSource.asObservable();

  public wheel = new BehaviorSubject<any>(true);
  wheelObservable = this.wheel.asObservable();

  public dataVisibleWheel = new BehaviorSubject<any>(true);
  dataVisibleWheelEntry = this.dataVisibleWheel.asObservable();

  public dataVisibleModal = new BehaviorSubject<any>(true);
  dataVisibleModalEntry = this.dataVisibleModal.asObservable();

  public dataWinningProduct = new BehaviorSubject<any>({});
  dataWinningProductObservable = this.dataWinningProduct.asObservable();

  public dataGUID = new BehaviorSubject<any>({});
  dataGUIDObservable = this.dataGUID.asObservable();

  public dataLoadingSource = new BehaviorSubject<any>(true);
  dataLoadingObservable = this.dataLoadingSource.asObservable();

  guid: any;

  constructor() { }

  public setStep(number: any){
    this.dataStepsSource.next(number);
  }

  setWheel(wheel: any){
    this.wheel.next(wheel);
  }

  setWinner(data: any){
    this.dataWinnerSource.next(data);
  }

  setVisibleWheel(visibility: any){
    this.dataVisibleWheel.next(visibility);
  }

  setVisibleModal(visibility: any){
    this.dataVisibleModal.next(visibility);
  }

  setWinningProduct(data: any){
    this.dataWinningProduct.next(data);
  }

  setLoading(loading: any){
    this.dataLoadingSource.next(loading);
  }

  setGUID(guid: any){
    this.guid = guid;
  }

  getGUID() {
    return this.guid;
  }
  
}

<div *ngIf="__step === 1">

    <div class="wheelContainer">

        <svg class="wheelSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            text-rendering="optimizeSpeed" preserveAspectRatio="xMidYMin meet">
            <defs>
                <filter id="shadow" x="-100%" y="-100%" width="550%" height="550%">
                    <feOffset in="SourceAlpha" dx="0" dy="0" result="offsetOut"></feOffset>
                    <feGaussianBlur stdDeviation="9" in="offsetOut" result="drop" />
                    <feColorMatrix in="drop" result="color-out" type="matrix" values="0 0 0 0   0
                0 0 0 0   0 
                0 0 0 0   0 
                0 0 0 .3 0" />
                    <feBlend in="SourceGraphic" in2="color-out" mode="normal" />
                </filter>
            </defs>
            <g class="mainContainer">
                <g class="wheel">
                </g>
            </g>
            <g class="centerCircle" />
            <g class="wheelOutline" />
            <g class="pegContainer" opacity="1">
                <path class="peg" fill="#000000" d="M22.139,0C5.623,0-1.523,15.572,0.269,27.037c3.392,21.707,21.87,42.232,21.87,42.232 s18.478-20.525,21.87-42.232C45.801,15.572,38.623,0,22.139,0z" />
            </g>
            <g class="valueContainer" />
            <g class="centerCircleImageContainer" />
        </svg>
        <div class="toast">
            <p></p>
        </div>

    </div>
                
    <div class="modal">
        <div class="modal__content">
            <div class="modal__content--title">BEFORE YOU SPIN AGAINPLEASE ANSWER THE FOLLOWING QUESTION:</div>
            <div class="modal__content--question"></div>
            <div class="modal__content--form">
                <form [formGroup]="formGroupQuestion" (ngSubmit)="formGroupQuestion.value">

                    <div class="modal__content--form__select">
                        <select formControlName="fieldSelect" class="js-populate-answers" (change)="changedSelect($event)" [ngClass]="{'error': formGroupQuestion.controls.fieldSelect.valid == false && formGroupQuestion.controls.fieldSelect.touched }"></select>
                    </div>

                    <div *ngIf="showOtherValue" class="modal__content--form__input">
                        <input type="text" placeholder="Answer" formControlName="fieldAnswer" [ngClass]="{'error': formGroupQuestion.controls.fieldAnswer.valid == false && formGroupQuestion.controls.fieldAnswer.touched }">
                    </div>

                    <div *ngIf="!answeredQuestion">
                        <a href="javascript:void(0);" (click)="spinAgain()" 
                        class="js-spin-again spinagain">SPIN AGAIN</a>
                        <a href="javascript:void(0);" class="exit">EXIT</a>
                    </div>
                    <div *ngIf="answeredQuestion">
                        <img src="assets/images/loader.gif" width="50" />
                    </div>
                </form>
            </div>
        </div>
    </div>
                
    <div class="modal__cellphone" [ngClass]="{'visible': showCellphoneMobile}">
        <div class="modal__content">
            <div class="modal__content--title">Before you Spin The Wheel, please insert your cellphone number:</div>
            <div class="modal__content--form">
                <form [formGroup]="formGroupCellphone" (ngSubmit)="formGroupCellphone.value">
                    <div class="modal__content--form__input">
                        <input type="text" placeholder="Enter cellphone number" formControlName="fieldCellphone" [ngClass]="{'error': formGroupCellphone.controls.fieldCellphone.valid == false && formGroupCellphone.controls.fieldCellphone.touched }">
                    </div>
                    <a href="javascript:void(0);" (click)="spin()" 
                    class="spinagain">SUBMIT</a>
                </form>
            </div>
        </div>
    </div>
                
    <div class="modal__wrong">
        <div class="modal__wrong__content">
            <div class="modal__wrong__content--title">WE’RE SORRY TO SEE YOU GO</div>
            <div class="modal__wrong__content--question">YOU ARE STILL ELIGIBLE FOR THE WEEKLY GRAND PRIZE OF A R1000 GROCERY VOUCHER.</div>
        </div>
    </div>
                
    <div class="modal__toomany">
        <div class="modal__toomany__content">
            <div class="modal__toomany__content--title">YOU HAVE SPUN THE WHEEL AND OUR CYLINDERS ARE HEATING UP</div>
            <div class="modal__toomany__content--question">We will however be back soon. In the meantime keep your eyes on our social media pages for updates on when you can spin again.</div>
        </div>
    </div>

    <input type="hidden" class="js-value-winner">
    <a href="javascript:void(0)" class="js-trigger-loading" (click)="showLoading()"></a>
    <a href="javascript:void(0)" class="js-trigger-loading-stop" (click)="showLoadingStop()"></a>
    <a href="javascript:void(0)" class="js-trigger-winner" (click)="changeScreen()"></a>

</div>
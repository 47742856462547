import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/main.service';
import axios from "axios";

declare var Spin2WinWheel: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  // step
  __step: number = 0;

  title = 'Wheel';
  routerUrlHome: any = true;
  routerUrlFaqs: any = false;
  guid: any = '';

  constructor(private service: MainService) {

    this.service.dataStepsEntry.subscribe( (__step) => {
      
      this.__step = __step;
      
    });
    
  }

  ngOnInit(): void {

  }

  routeHome() {
    
    this.service.setStep(1);

  }

  routeFaqs() {
    
    this.service.setStep(2);

  }

  routeWinner() {
    
    this.service.setStep(3);

  }

  routeOptOut() {
    
    this.service.setStep(4);

  }

}
<div class="winner" *ngIf="__step === 4">

    <div class="winner__heading">

        congratulations YOU’VE WON

    </div>

    <div class="winner__content" *ngIf="__winner?.campaignId === 'Tiger_SpinToWin_PROD_202008_Airtime_Pinless_R10'">

        <div class="winner__content--title">

            <div class="winner__content--title__main--airtime">

                R10
                
            </div>

            <div class="winner__content--title__description--airtime">

                Airtime voucher

            </div>

        </div>

    </div>

    <div class="winner__content" *ngIf="__winner?.campaignId === 'Tiger_SpinToWin_PROD_202008_Airtime_Pinless_R20'">

        <div class="winner__content--title">

            <div class="winner__content--title__main--airtime">

                R20
                
            </div>

            <div class="winner__content--title__description--airtime">

                Airtime voucher

            </div>

        </div>

    </div>

    <div class="winner__content" *ngIf="__winner?.campaignId === '(v3)Spintowin_R50_Shoprite_tiger'">

        <div class="winner__content--logo" [style.backgroundImage]="'url('+__winner?.image+')'"></div>

        <div class="winner__content--title">

            <div class="winner__content--title__main">

                R50
                
            </div>

            <div class="winner__content--title__description">

                Product voucher

            </div>

        </div>

    </div>

    <div class="winner__content" *ngIf="__winner?.campaignId === '(v3)Spintowin_R100_Shoprite_tiger'">

        <div class="winner__content--logo" [style.backgroundImage]="'url('+__winner?.image+')'"></div>

        <div class="winner__content--title">

            <div class="winner__content--title__main">

                R100
                
            </div>

            <div class="winner__content--title__description">

                Product voucher

            </div>

        </div>

    </div>

    <div class="winner__content" *ngIf="__winner?.campaignId === '(v3)Spintowin_R1000_Shoprite_tiger'">

        <div class="winner__content--logo" [style.backgroundImage]="'url('+__winner?.image+')'"></div>

        <div class="winner__content--title">

            <div class="winner__content--title__main">

                R1000
                
            </div>

            <div class="winner__content--title__description">

                Grocery voucher

            </div>

        </div>

    </div>

    <div class="winner__form">

        <form [formGroup]="formRedeem" (ngSubmit)="formRedeem.value">

            <div class="winner__form--label">
    
                please fill in your full name below
    
            </div>
    
            <div class="winner__form--input">
    
                <input type="text" placeholder="John Doe" formControlName="fieldName" [ngClass]="{'error': formRedeem.controls.fieldName.valid == false && formRedeem.controls.fieldName.touched }">
    
            </div>
    
            <div class="winner__form--button">
    
                <div *ngIf="!__answered">

                    <a href="javascript:void(0)" (click)="submit()">REDEEM VOUCHER</a>

                </div>
    
                <div *ngIf="__answered">

                    <img src="assets/images/loader.gif" width="50" />

                </div>
    
            </div>

        </form>

    </div>

    <div class="winner__terms">

        TERMS & CONDITIONS: To view the Terms & Conditions, <a href="/terms_and_conditions.pdf" target="_blank">Click Here</a>

    </div>

</div>
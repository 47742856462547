import { Component, OnInit } from '@angular/core';
import { MainService } from '../main.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  __loading: boolean = false;

  constructor(private service: MainService) { }

  ngOnInit(): void {

    this.service.dataLoadingObservable.subscribe( (__loading) => {
      
      this.__loading = __loading;
      
    });

  }

}
